.app__header{
    background-color: #3A3136;
}
.app__header-h1{
    font-family: var(--font-base) ;
    color: #D9CCC5 ;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.app__header-img img{
    width: 100%;

}