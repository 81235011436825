.app__rooms {
    background-color: #3A3136;
  }
  
  .app__rooms-h1 {
    font-family: var(--font-base);
    color: #D9CCC5;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 0px;
  }
  
  .app__header {
    background-color: #3A3136;
  }
  
  .app__header-h1 {
    font-family: var(--font-base);
    color: #D9CCC5;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 60px;
  }
  .app__bullet-list {
    list-style-type: disc; 
    margin: -2rem 0; 
    padding-left: 1.5rem; 
  }
  
  
  
  